<template>
  <loading
    v-if="loading > 0"
    class="absolute-center" />
  <v-container
    v-else
    grid-list-lg
    class="case-channels">
    <v-tooltip
      left
      class="btn-tooltip">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="!hideActionButton"
          color="primary"
          class="btn-fixed-bottom mb-2 mr-2"
          small
          dark
          fab
          v-on="on"
          @click="createNewInvite">
          <v-icon>{{ $root.icons.action.add }}</v-icon>
        </v-btn>
      </template>
      <span>Criar uma novo convite</span>
    </v-tooltip>
    <v-layout
      row
      wrap>
      <v-flex
        xs12
        sm12
        class="py-0">
        <v-data-table
          class="table-channels"
          :headers="headers"
          :items="invites"
          hide-default-footer>
          <template
            v-slot:item="props"
            class="transparent">
            <tr>
              <td class="px-2">
                <v-tooltip
                  bottom
                  content-class="table_trasparent">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      depressed
                      v-on="on">
                      <label-icon :type="$t('invite.channel.' + props.item.channel)" />
                    </v-btn>
                  </template>
                  {{ props.item.params.recipient.to }}
                </v-tooltip>
              </td>
              <td class="px-2 text-center">
                {{ props.item.token }}
              </td>
              <td class="px-2 text-center">
                {{ $t('invite.state.' + props.item.state) }}
              </td>
              <td class="px-2 text-center">
                <v-btn
                  title="Reenviar"
                  icon
                  small
                  color="bgColor"
                  class="mini"
                  fab
                  depressed
                  :disabled="checkState(props.item.state)"
                  @click="modalResend(props.item)">
                  <v-icon
                    small
                    color="primary">
                    {{ $root.icons.action.replay }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <router-view />
    <modal
      ref="communicationModal"
      :item="rowSelected" />
  </v-container>
</template>

<script>
  import LabelIcon from '@/components/ui/LabelIcon'
  import casesMixin from '@/mixins/cases'
  import { CHANNELS_STATE, CASE_STATE } from '@/utils/states'
  import Modal from '@/components/ui/ModalResend'
  import { mapGetters } from 'vuex'

  export default {
    components: { LabelIcon, Modal },
    mixins: [ casesMixin ],
    data: () => ({
      headers: [
        { text: 'Tipo', value: 'type', sortable: false },
        { text: 'Token', value: 'token', align: 'center', sortable: false },
        { text: 'Status', value: 'status', align: 'center', sortable: false },
        { text: 'Reenvio', value: null, align: 'center', sortable: false }
      ],
      rowSelected: {}
    }),
    computed: {
      ...mapGetters({
        invites: 'invites/listByCase',
        loading: 'invites/listByCaseLoading'
      }),
      hideActionButton () {
        const state = this.case && this.case.state
        return state === CASE_STATE.refused || state === CASE_STATE.accepted || state === CASE_STATE.expired
      }
    },
    beforeMount () {
      this.$store.dispatch('invites/getListByCase', this.$route.params.caseId)
    },
    methods: {
      checkState (state) {
        let result = state === CHANNELS_STATE.email.dropped || state === CHANNELS_STATE.email.bounce
        return result || this.hideActionButton
      },
      modalResend (item) {
        this.rowSelected = item
        this.$refs.communicationModal.modalResend(item)
      },
      createNewInvite () {
        this.$router.push({ name: 'CaseChannelsNew' })
      }
    }
  }
</script>

<style lang="sass">
  .btn-fixed-bottom
    position: fixed
    bottom: 0
    right: 10px

    &:hover
      position: fixed

  .case-channels
    height: 100%
    transform: translateX(0)

    .btn-tooltip
      z-index: 1

      // + .layout
      //   background: #fff
      //   position: relative
      //   z-index: 2
</style>
